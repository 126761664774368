<script lang="ts">
  import { goto } from '$app/navigation';
  import { authenticateUser } from '$lib/auth/auth';
  import eventStore, { createEventTracker } from '$lib/stores/eventStore';
  import { getLogoutUrl } from '$lib/utils/redirect';
  import { setTheme } from '$lib/utils/theme';
  import type { Subscriber } from '$lib/ws/client';
  import { taskSubscribe } from '$lib/ws/ws-task';
  import '$src/app.css';
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { onDestroy, onMount } from 'svelte';

  let taskSubscriber: Subscriber;

  onMount(async () => {
    let user = await authenticateUser();
    if (!user) {
      goto(getLogoutUrl());
    } else {
      createEventTracker();
      taskSubscriber = taskSubscribe($eventStore);
      setTheme();
    }
  });

  onDestroy(() => {
    if (taskSubscriber) {
      taskSubscriber.unsubscribe();
    }
  });
</script>

<div class="h-full w-full dark:text-white bg-ai8-white dark:bg-ai8-chat-gray">
  <slot />
</div>
<SvelteToast />
