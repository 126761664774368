import eventTrackerClient, { type EventTracker } from '$lib/ws/client';
import { writable } from 'svelte/store';

const eventStore = writable<EventTracker>();

export default {
  subscribe: eventStore.subscribe,
  set: eventStore.set,
};

export const createEventTracker = () => {
  eventStore.set({
    ...eventTrackerClient(),
  });
};
