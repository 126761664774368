import { browser } from '$app/environment';
import { PREFERRED_DARK_MEDIA_THEME as PREFERRED_DARK_MEDIA_THEME_KEY } from '$lib/utils/constants';

export function setTheme() {
  if (browser) {
    if (window.matchMedia(PREFERRED_DARK_MEDIA_THEME_KEY).matches) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
  }
}

export function setDarkTheme() {
  document.documentElement.classList.add('dark');
  localStorage.theme = 'dark';
}

export function setLightTheme() {
  document.documentElement.classList.remove('dark');
  localStorage.theme = 'light';
}
