import { get } from 'svelte/store';

import { getCurrentUser } from '$lib/api/auth';
import * as authService from '$lib/services/auth';
import userStore from '$lib/stores/userStore';
import type { TaigaUser } from '$lib/types/user';

export const authenticateUser = async () => {
  const user = get(userStore)?.user;
  if (user) {
    return user;
  }
  const { data, error } = await getCurrentUser();
  if (error) {
    console.log(error);
  } else {
    userStore.set({ user: data });
    return data;
  }
};

export const authenticateTaigaUser = async () => {
  const user = get(userStore)?.user;
  if (user) {
    return;
  }
  const data = await authService.getTaigaCurrentUser();
  userStore.set({ user: data as TaigaUser });
};
