import type { User } from '$lib/types/response';
import type { TaigaUser } from '$lib/types/user';
import { writable } from 'svelte/store';

const userStore = writable<{
  user?: User | TaigaUser | null;
}>();

export default {
  subscribe: userStore.subscribe,
  update: userStore.update,
  set: userStore.set,
};
