import { getUserTokenFromBrowser } from '$src/axios/interceptors';
import { env } from '$src/env';

type Callback = (...args: unknown[]) => void;

export interface Subscriber {
  unsubscribe: () => void;
}

type ISubscription = [string, Callback];

export interface EventTracker {
  emit: (event_type: string, data?: object) => void;
  subscribe: (event_type: string, callback: Callback) => Subscriber;
}

const eventTrackerClient = () => {
  let subscriptions: ISubscription[] = [];

  const wsURL = new URL(env.eventTrackerUrl);
  wsURL.searchParams.set('token', getUserTokenFromBrowser() as string);

  let ws = new WebSocket(wsURL);

  const connectToWs = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      return;
    }
    ws = new WebSocket(wsURL);
  };

  const retryConnection = () => {
    setTimeout(() => {
      console.log('Retrying WebSocket connection.');
      connectToWs();
    }, Number(env.eventTrackerReconnectInterval ?? 5000));
  };

  const emit = (event_type: string, data?: object) => {
    const message = { event_type, data };
    ws.send(JSON.stringify(message));
  };

  const subscribe = (event_type: string, callback: Callback) => {
    const subscription: ISubscription = [event_type, callback];
    const subscriber: Subscriber = {
      unsubscribe: () => {
        subscriptions = subscriptions.filter((s) => s !== subscription);
      },
    };
    subscriptions.push(subscription);
    return subscriber;
  };

  ws.onopen = () => {
    console.log('WebSocket connection opened.');
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed.');
    retryConnection();
  };

  ws.onerror = (e) => {
    console.error('WebSocket error:', e);
    retryConnection();
  };

  ws.onmessage = (e) => {
    const message = JSON.parse(e.data);
    const { event_type, data } = message;
    subscriptions
      .filter(([subscribedEvent, _]) => subscribedEvent === event_type)
      .forEach(([_, callback]) => callback(data));
  };

  connectToWs();

  return { emit, subscribe };
};

export default eventTrackerClient;
