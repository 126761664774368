import type { UserPatchRequest, UserSettingsRequest } from '$lib/types/request';
import type { AxiosPromise, TaigaUserResponse, UserSettingsResponse } from '$lib/types/response';
import { ai8Api, taigaApi } from '$src/axios/api';

const TAIGA_USER_BASE_ROUTE = 'my/user';

export const getCurrentUser = async () => {
  return ai8Api.get('user/current/');
};

export const getTaigaCurrentUser = async (): AxiosPromise<TaigaUserResponse> => {
  return taigaApi.get(TAIGA_USER_BASE_ROUTE);
};

export const updateTaigaCurrentUser = async (settings: UserSettingsRequest): AxiosPromise<UserSettingsResponse> => {
  return taigaApi.put(`${TAIGA_USER_BASE_ROUTE}/settings`, settings);
};

export const getUserProfile = async () => {
  return ai8Api.get('user/profile');
};

export const patchUser = async (id: number, data: UserPatchRequest) => {
  return ai8Api.patch(`user/${id}/`, data);
};
